import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggle(event) {
    const textElement = this.element.querySelector(".truncated-text");
    const fullText = textElement.dataset.fullText;
    const isTruncated = textElement.textContent.endsWith("...");
  
    if (isTruncated) {
      textElement.innerHTML = textElement.dataset.fullHtml;
      event.target.classList.add("rotated");
    } else {
      textElement.innerHTML = textElement.dataset.truncatedHtml;
      event.target.classList.remove("rotated");
    }
  }  
}
