import { Controller } from "@hotwired/stimulus"
import $ from "jquery"
import Select2 from "select2"

export default class extends Controller {
  connect() {
    // Define the select all adapter
    Select2()
    $.fn.select2.amd.define('select2/selectAllAdapter', [
      'select2/utils',
      'select2/dropdown',
      'select2/dropdown/attachBody'
    ], function (Utils, Dropdown, AttachBody) {
      function SelectAll() { }
      SelectAll.prototype.render = function (decorated) {
        var self = this,
            $rendered = decorated.call(this),
            $selectAll = $(
              '<button class="btn btn-xs btn-default" type="button" style="margin-left:6px;">すべて選択</button>'
            ),
            $unselectAll = $(
              '<button class="btn btn-xs btn-default" type="button" style="margin-left:6px;">選択解除</button>'
            ),
            $btnContainer = $('<div style="margin-top:3px;">').append($selectAll).append($unselectAll);

        if (!this.$element.prop("multiple")) {
          return $rendered;
        }

        $rendered.find('.select2-dropdown').prepend($btnContainer);

        $selectAll.on('click', function (e) {
          var $visibleOptions = $rendered.find('.select2-results__option[role="option"]:not(.select2-results__option--hidden)');
          $visibleOptions.each(function() {
            var $option = $(this);
            // Get the text content of the option
            var text = $option.text();
            // Find the option in the original select that matches this text
            self.$element.find('option').filter(function() {
              return $(this).text() === text;
            }).prop('selected', true);
          });
          self.$element.trigger('change');
          self.trigger('close');
        });

        $unselectAll.on('click', function (e) {
          var $select = self.$element;
          var $options = $select.find('option:selected');

          $options.prop('selected', false);
          $select.trigger('change');
          self.trigger('close');
        });

        return $rendered;
      };

      return Utils.Decorate(
        Utils.Decorate(
          Dropdown,
          AttachBody
        ),
        SelectAll
      );
    });

    // Initialize Select2 with the custom adapter
    $('.js-select2').select2({
      multiple: true,
      placeholder: "施術を選択",
      dropdownAdapter: $.fn.select2.amd.require('select2/selectAllAdapter')
    });
  }
}
